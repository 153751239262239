.root {
  position: relative;
  height: 2rem;
  padding: spacing(0, 2, 0, 4);
  font-family: var(--fontFamily-sofia);
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 2em;
  color: var(--black);
  text-decoration: none;
  border: 2px solid var(--black);
  border-radius: spacing(2);

  &:active,
  &:hover,
  &:visited {
    color: var(--black);
  }
}

.svg {
  position: absolute;
  top: 50%;
  left: 0;
  width: spacing(3);
  height: spacing(3);
  transform: translate(1px, -50%);
}

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.rootWithFooter {
  min-height: calc(100vh + var(--footerHeight));
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.shadow {
  border-bottom: 1px var(--grey-chewie) solid;
}

.logo {
  padding: 1rem;
  padding-top: 1.5rem;
}

.root {
  max-width: var(--content-max-width-desktop-large);
  padding: spacing(1) spacing(2);

  a {
    text-decoration: none;
  }
}

.logoSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: spacing(2);
}

.centerAlign {
  text-align: center;
}

.topBorder {
  border-top: 2px solid var(--grey-chewie);
}

.logo {
  width: 140px;
}

.item {
  margin-top: spacing(0.5);
  font-size: 14px;
  font-weight: 400;
}

.linksSection {
  display: flex;
  align-items: end;
  margin-right: spacing(1);
}

.title {
  font-size: 18px;
}

.subtitle {
  font-size: 14px;
}

.expandable {
  padding: spacing(2);
  margin: spacing(1.5) 0;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: var(--light-drop-shadow);
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: var(--strong-drop-shadow);
  }
}

.answerText {
  display: block;
  padding: spacing(1, 0);
  font-size: 14px;
}

.questionText {
  font-size: 14px;
  font-weight: 600;
}

.innerExpandable {
  mask-image: linear-gradient(to top, transparent, black 6px, black 100%, transparent);
}

.root {
  font-family: var(--fontFamily-sofia);
  line-height: 1.5;
}

.block {
  display: block;
}

.align-inherit {
  text-align: inherit;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.p {
  display: block;
  font-size: 1em;
  line-height: 1.5;

  & + & {
    margin-top: 1em;
  }

  &-serif {
    font-family: var(--fontFamily-sofia);

    & + & {
      margin-top: 1em;
    }
  }
}

.text {
  font-size: 1em;
  line-height: 1.5;

  & + & {
    margin-top: 1em;
  }
}

.link {
  font-size: 1em;
  font-weight: var(--bold);
}

.blockquote {
  position: relative;
  display: block;
  padding-left: 36px;
  font-family: var(--fontFamily-sofia);
  font-size: 1em;
  line-height: 1.5;
  color: #6a6a6a;

  &::before {
    position: absolute;
    top: 0;
    left: 16px;
    width: 4px;
    height: 100%;
    content: ' ';
    background-color: #ebebeb;
    border-radius: 2px;
  }

  @media ($phone-down) {
    padding-left: 28px;

    &::before {
      left: 8px;
    }
  }
}

.headingRoot {
  margin-bottom: 0.75em;
  font-family: var(--fontFamily-sofia);
  font-weight: var(--bold);
}

.hero {
  composes: headingRoot;
  font-size: 1.75em;
  line-height: 1.33em;
}

.heading {
  composes: headingRoot;
  font-size: 1.5em;
}

.smallheading {
  composes: headingRoot;
  font-size: 1.25em;
}

.subheading {
  font-family: var(--fontFamily-sofia);
  font-size: 1em;
  font-weight: bold;
}

.title {
  display: block;
  font-family: var(--fontFamily-sofia);
  font-size: 20px;
  font-weight: bold;
}

.largetitle {
  composes: title;
  font-size: 24px;
}

.small {
  font-family: var(--fontFamily-sofia);
  font-size: 0.875em;
}

.supersmall {
  font-family: var(--fontFamily-sofia);
  font-size: 0.75em;
}

.strong {
  font-weight: var(--bold);
}

.raw {
  margin-bottom: 0;
}

.faded {
  font-size: 0.875rem;
  line-height: 1.5;

  & + & {
    margin-top: 0.875rem;
  }
  color: var(--grey-lando) !important;
}

/*
To be used with markdown where the Typography acts as a parent for preformatted text.
css composition makes it hard to do this in a nice way, so for now we just
recreate the relevant styles.*/

.md {
  p {
    font-size: 1em;
    line-height: 1.5;
  }

  span[data-highlight] {
    position: relative;
    display: inline-block;
    padding-right: 1em;
    cursor: pointer;
    background-color: var(--grey-chewie);

    &:hover {
      background-color: var(--grey-luke);
    }

    &::after {
      position: absolute;
      right: 0.125em;
      bottom: 0.27em;
      display: inline-block;
      width: 0.75em;
      height: 0.75em;
      content: '';
      background-image: url('/img/info-24px.svg');
      background-size: 0.75em 0.75em;
    }
  }

  ol,
  ul {
    padding-left: 1.75em;
    margin: spacing(2, 0);
  }

  ol {
    list-style: outside decimal;
  }

  ul {
    list-style: outside disc;
  }

  li {
    padding-left: 0.5em;
  }

  li + li {
    margin-top: 0.5em;
  }

  p + p {
    margin-top: 1em;
  }

  b,
  strong {
    font-weight: var(--bold);
  }

  em,
  i {
    font-style: italic;
  }

  a {
    font-weight: var(--bold);
    color: var(--denim);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  h1,
  h2,
  h3 {
    margin-bottom: 0.75em;
    font-family: var(--fontFamily-sofia);
    font-weight: var(--bold);
  }

  h1 {
    /* heading*/
    font-size: 1.5em;
  }

  h2 {
    /*smallheading*/
    font-size: 1.25em;
  }

  h3 {
    /* subheading */
    font-size: 1.125em;
  }
}

.md-serif {
  composes: md;
  font-family: var(--fontFamily-sofia);
}

.md-sans {
  composes: md;

  h1,
  h2,
  h3 {
    font-family: var(--fontFamily-sofia) !important;
  }
}

.info {
  display: block;
  font-size: 0.75em;
  line-height: 1.5;
  color: var(--grey-lando) !important;
}

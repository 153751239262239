.bookingBlock {
  background: linear-gradient(to bottom, var(--grey-leia), transparent);

  * {
    font-family: var(--fontFamily-sofia) !important;
  }

  h1 {
    margin-top: spacing(3);
    margin-bottom: spacing(0.5);
    font-size: 24px;
  }

  h2 {
    margin-top: spacing(3);
    margin-bottom: spacing(0.5);
    font-size: 18px;
  }

  h3 {
    margin-top: spacing(3);
    margin-bottom: spacing(0.5);
    font-size: 16px;
  }

  p {
    font-size: 14px;
  }

  ul li {
    font-size: 14px;
  }

  a {
    color: var(--black);
    text-decoration: underline;
  }

  div {
    max-width: 600px;
  }
}

.root {
  position: relative;
  padding: spacing(0, 2, 0, 1);
}

.svg {
  position: absolute;
  top: 50%;
  left: 0;
  width: spacing(3);
  height: spacing(3);
  transform: translate(1px, -50%);
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.avatar {
  width: 126px;
  height: 126px;
  margin: spacing(3, 0);
  overflow: hidden;
  border-radius: 100%;

  img {
    width: 100%;
    object-fit: contain;
  }
}

.about {
  margin-top: 20px;
}

.profession {
  margin-bottom: 0.25rem;
  color: var(--grey-lando) !important;
  text-align: center;
}

.name {
  margin-bottom: 0.25rem;
  font-size: 1.25rem;
  text-align: center;
}

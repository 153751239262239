.navItem {
  height: 100%;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: spacing(1.5, 0);
  font-family: var(--fontFamily-sofia);
  font-size: 2rem;
  font-weight: var(--bold);
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.menu {
  position: fixed;
  z-index: var(--z-navMenu);
  width: 100%;
  height: 100vh;
  pointer-events: none;
  background-color: var(--white);
  transition: transform 0.35s ease-out;
  transform: translate(25%, -120%) rotate(25deg);
  transform-origin: 100% 100%;
  will-change: transform;

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
}

.aboveMenu {
  z-index: var(--z-top);
}

.leftFiller {
  position: absolute;
  left: -100%;
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--white);

  .pink & {
    background-color: var(--baby-pink);
  }

  .black {
    background-color: var(--black);
  }
}

.pink {
  background-color: var(--baby-pink);
}

.black {
  background-color: var(--black);
}

.bottomFiller {
  position: absolute;
  top: 100%;
  width: 100%;
}

.menuVisible {
  pointer-events: all;
  transition: transform 0.25s ease-in;
  transform: none; /** gives a slight performance boost */

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
}

.menuInner {
  width: 100%;
  height: 100%;
  padding-top: var(--navHeight);
  opacity: 0;
  transition: opacity 0.3s;

  @media (prefers-reduced-motion: reduce) {
    opacity: 1;
    transition: none;
  }
}

.innerVisible {
  opacity: 1;
}

.closeButton {
  position: fixed;
  top: spacing(2);
  right: spacing(2);
}

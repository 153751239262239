.root {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid var(--lightgrey);
}

.rightPart {
  display: flex;
  align-items: center;

  ul {
    display: flex;
    margin-right: 1em;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navItem {
  margin: 0 0.5em;

  a {
    padding: 0.75em 1em;
    font-size: 16px;
    font-weight: normal;
    text-decoration: none !important;
  }
  border-bottom: 2px solid transparent;

  &Active {
    border-color: var(--soft-green);
  }
}

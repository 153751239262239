.wrapper {
  z-index: var(--z-above);
  visibility: hidden;
  opacity: 0;
  transition: opacity 50ms cubic-bezier(0.4, 0, 1, 1);
}

.wrapperOpen {
  visibility: visible;
  opacity: 1;
}

.container {
  padding: spacing(2);
}

.root {
  position: absolute;
  z-index: var(--z-above);
  max-width: 300px;
  overflow: visible;
  color: var(--white);
  background: var(--verydark);
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.fixedRoot {
  position: fixed;
}

.arrow {
  position: absolute;
  z-index: var(--z-above);
  display: block;
  width: 10px;
  height: 10px;
  content: ' ';
  box-shadow: 2px 6px 6px 0 rgb(0, 0, 0, 0.2);
  transform: translate(-50%, -50%);

  &::before {
    position: absolute;
    left: 50%;
    display: block;
    content: ' ';
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    transform: translate(-50%, -50%);
  }
}

.arrowAbove {
  box-shadow: 2px -4px 6px -2px rgba(0, 0, 0, 0.2);

  &::before {
    top: 0;
    border-bottom: 14px solid var(--verydark);
  }
}

.arrowBelow {
  &::before {
    bottom: 0;
    border-top: 14px solid var(--verydark);
    transform: translate(-50%, 50%);
  }
}

.arrowFixed {
  position: fixed;
}

.typography {
  word-break: break-word;
  white-space: pre-wrap;
}

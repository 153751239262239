.boxStyleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  border: 2px solid var(--black);
}

.languagePickerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  border-top: 1px solid var(--lightgrey);
}

.base {
  display: inline-flex;
  align-items: center;
  height: 32px;
  padding: 0 spacing(1);
  padding-left: spacing(0.25);
  border: 2px solid var(--black);
  border-radius: 2rem;
}

.baseStarted {
  display: flex;
}

.playPauseButton {
  position: relative;
  flex-shrink: 0;
  width: 26px;
  height: 26px;
  padding: 0;
  color: var(--black);

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
  }
}

.timeline {
  position: relative;
  width: 100%;
  height: 10px;
  margin: 0 0.75rem;

  &::before {
    position: absolute;
    top: 4px;
    right: 0;
    left: 0;
    height: 2px;
    content: '';
    background-color: var(--black);
  }
}

.innerTimeline {
  position: relative;
  height: 10px;

  &::after {
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: 18px;
    height: 18px;
    content: '';
    background-color: var(--black);
    border: 2px solid var(--white);
    border-radius: 100%;
    transition: left 100ms linear;
    transform: translate(50%, -50%);
  }
}

.timestamp {
  min-width: 30px;
  font-size: 0.75rem;
  line-height: 0.75rem;
  text-align: right;
  white-space: nowrap;
  vertical-align: middle;
}

.unplayed {
  padding-left: 0.5rem;
  font-size: 0.75rem;
  font-weight: var(--bold);
}

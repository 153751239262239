.title {
  margin-bottom: 0;
}

.tab {
  padding: 8px 16px;
  cursor: pointer;
  user-select: none;
  border: 2px solid var(--grey-chewie);
  border-radius: 4px;

  &.active {
    border-color: black;
  }

  span {
    display: block;
    margin: 0;
    font-size: 12px;
    font-weight: 600;
  }
}

body:global(.accessibility-mode) .tab {
  &:focus {
    background-color: var(--grey-chewie);
    border-color: blue;
  }
}

.textContainer {
  overflow: hidden;
  transition: height 0.2s;
  will-change: height;
}

.embla {
  width: 100%;
  overflow: hidden;
}

.embla__container {
  display: flex;
  padding-top: 24px;
  padding-bottom: 16px;
}

.embla__slide {
  position: relative;
  flex: 0 0 auto;
  width: max-content;
  padding-right: 4px;
}

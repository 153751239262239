.root {
  overflow: hidden;
  will-change: height;
}

.content {
  padding: spacing(2, 0);

  &.noPadding {
    padding: 0;
  }
}

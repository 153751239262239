.float {
  width: 50%;

  .childCont {
    width: 100%;
  }

  &.position {
    &-top-left {
      float: left;
      margin-right: 8px;
    }

    &-top-right {
      float: right;
      margin-left: 8px;
    }

    &-bottom-left {
      float: left;
      clear: left;

      .floatInner {
        width: 100%;
        padding-right: 8px;
      }
    }

    &-bottom-right {
      float: right;
      clear: right;

      .floatInner {
        width: 100%;
        padding-left: 8px;
      }
    }
  }
}

.root {
  border-bottom: 1px solid var(--subdued);
}

.rawRoot {
  border-bottom: 0 !important;
}

.opener {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: inherit;
  font-size: 1.125rem;
  color: inherit;
  text-align: left;
  background-color: transparent;
  border: none;
}

body:global(.accessibility-mode) .opener {
  &:focus {
    background-color: var(--grey-chewie);
    outline: 2px solid blue !important;
  }
}

.title {
  padding-right: spacing(2);
  font-size: 1rem;
  font-weight: 800;
  color: black;
}

.indicator {
  flex-shrink: 0;
  margin-right: 4px;
  color: var(--grey-solo);
  transition: transform 0.3s;

  [aria-expanded='true'] > & {
    transform: rotate(-180deg);
  }
}

.content {
  line-height: 1.5;
}

.buttonPadding {
  padding: spacing(3, 0);
}

.noButtonPadding {
  padding: 0;
}

.root {
  width: 100%;
  padding-bottom: spacing(4);

  & > * {
    word-wrap: break-word;
  }
}

.embedWrapper {
  margin: 0;
}

.employeeWrapper {
  h3 ~ & {
    margin-top: spacing(2);
  }
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;

  @media ($phone-down) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.blockSection {
  margin-bottom: spacing(0);
}

.blockButton {
  display: block;
}

.tabsWithContentRoot {
  padding-bottom: 0;
}

.floatImageTextRendererContent {
  * {
    display: inline;
  }
}

.iconCardTitle {
  margin-bottom: spacing(1);
  font-size: 24px;
}

.iconCardBody {
  font-size: 14px;
}

.iconCardContainer {
  position: relative;
  padding: spacing(3) spacing(2) spacing(4.5);
  margin-right: -12px;
  margin-bottom: spacing(2);
  margin-left: -12px;
  border-radius: 8px;
  box-shadow: var(--light-drop-shadow);
}

.iconCardIcon {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 40px;
  padding: 8px;
  background-color: var(--black);
  border-radius: 50%;
  transform: translate(-50%, 50%);
}

.root {
  padding: spacing(5) 0 !important;
  color: var(--white);
  text-align: center;
  background-color: var(--black);

  a {
    text-decoration: none;
  }
}

.centerAlign {
  text-align: center;
}

.topBorder {
  border-top: 2px solid var(--grey-chewie);
}

.logo {
  width: 140px;
}

.item {
  margin-top: 1em;
}
